<!-- 收款信息 -->
<template>
  <el-dialog
      width="40%"
      center
      :visible="visible"
      :lock-scroll="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :title="isUpdate?'收款信息':'收款信息'"
      @update:visible="updateVisible">
    <el-form
        ref="form"
        :rules="rules"
        :model="form"
        label-width="120px">
      <el-row :gutter="18">
        <el-col :span="24">
          <el-form-item label="银行卡上传识别:" prop="">
            <el-upload
                :action="action"
                :headers="headers"
                :on-success="successUpload"
                :limit="1"
                accept=".jpg, .png, jpeg"
                :file-list="fileList"
                :class="{hide_box: handupload_btn}"
                :on-change="handchange"
                list-type="picture-card"
                :on-preview="handPreview"
                :on-remove="handhandleRemove">
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="handdialogVisible" :before-close="handleClose" :modal="false">
              <img width="100%" :src="handdialogImageUrl" alt="">
            </el-dialog>
          </el-form-item>
          <el-form-item label="银行账号:" prop="">
            <el-input
                clearable
                :maxlength="20"
                v-model="form.realname"
                placeholder="请输入"/>
          </el-form-item>
          <el-form-item label="持卡人姓名:" prop="">
            <el-input
                clearable
                :maxlength="20"
                v-model="form.phone"
                placeholder="请输入"/>
          </el-form-item>
          <el-form-item label="持卡人姓名:" prop="">
            <el-input
                clearable
                :maxlength="20"
                v-model="form.phone"
                placeholder="请输入"/>
          </el-form-item>
          <el-form-item label="金额:" prop="">
            <el-input
                clearable
                :maxlength="20"
                v-model="form.phone"
                placeholder="请输入"/>
          </el-form-item>
          <el-form-item label="线下支单:" prop="">
            <el-input
                clearable
                :maxlength="20"
                v-model="form.phone"
                placeholder="请输入"/>
          </el-form-item>
          <el-form-item label="车主车牌号:" prop="">
            <el-input
                clearable
                :maxlength="20"
                v-model="form.phone"
                placeholder="请输入"/>
          </el-form-item>
          <el-form-item label="转账流水号:" prop="">
            <el-input
                clearable
                :maxlength="20"
                v-model="form.phone"
                placeholder="请输入"/>
          </el-form-item>
        </el-col>
      </el-row>

    </el-form>
    <div slot="footer">
      <el-button
          @click="updateVisible(false)">取消
      </el-button>
      <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          @click="save">确定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
// 获取配置
import setting from '@/config/setting'
import {getCookies} from '@/utils/cookies'
import Config from '@/config'

export default {
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    // 字典数据
    dict: Object,
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      // 验证规则
      rules: {
        type:[
          { required: true, message: '请选择类型', trigger: 'change' }
        ],
        realname: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
        ],
        phone:[
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { min: 5, max: 20, message: '长度在5 到 20个字符', trigger: 'blur' }
          // {
          //   pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
          //   message: "请输入正确的手机号码",
          //   trigger: "blur"
          // }
        ]
      },

      // 上传接口
      action: setting.apiBaseURL + 'common/upload/order',
      // 上传请求头部
      headers:{
        'Authori-Zation' : 'Bearer' + ' ' + getCookies(Config.tokenStoreName)
      },
      //银行卡照片
      handdialogImageUrl:'',
      handdialogVisible:false,
      handupload_btn:false,
      fileList:[],

    };
  },
  watch: {
    data() {
      if (this.data) {
        console.log(this.data)
        this.form = Object.assign({}, this.data);
        this.isUpdate = true;
      } else {
        this.form = {};
        this.isUpdate = false;
      }
    }
  },
  mounted() {
    console.log('收款信息收款信息收款信息')
  },
  methods: {
    //银行卡
    handchange(){
      this.handupload_btn = true;
    },
    handhandleRemove(){
      this.fileList = [];
      this.handupload_btn = false;
    },
    handPreview(file){
      this.handdialogImageUrl = file.url;
      this.handdialogVisible = true;
    },
    handleClose(){
      this.handdialogVisible = false;
    },
    //上传成功
    successUpload(file,fileList){
      console.log(file);
      console.log(fileList)
      this.handdialogImageUrl = file.data.urls;
      // 上传成功后的列表
      this.form.sample_graph_path = file.data.urls;
    },

    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          //判断是新增还是修改
          if(this.isUpdate === false){

          }else {

          }
        } else {
          return false;
        }
      });
    },

    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },
  }
}
</script>

<style scoped lang="scss">
  .hide_box /deep/ .el-upload--picture-card {
    display: none;
  }
</style>
